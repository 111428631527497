.titleName {
  font-size: $fs-16;
  color: var(--secondary-200);
  line-height: 1.35;
  min-width: none;
  margin: 5px 0 0 0;
}
.date {
    font-size: $fs-12;
    color: var(--secondary-400);
    margin: 6px 0 0 0;
    span {
      color: $primary-200;
      text-transform: uppercase;
      border-radius: 4px;
      background-color: var(--secondary-700);
      display: inline-block;
      padding: 0px 5px;
      margin-right: 4px;
      & ~ span {
        margin-left: 12px;
      }
    }
  }
