.bid-main-container {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 12px;
  background-color: #23272e;

  .team-btn{
    height: 40px;
    width: 70px;
    font-size: 18px;
    border-radius: 14px;
  }

  .bid-btn{
    height: 100px;
    width: 100px;
    border-radius: 15px;
    font-size: 24px;
    text-wrap: wrap;
    text-align: center;
  }

  .bid-team-img{
    height: 32px;
    width: 32px;
  }


  
  .selected-player{
    background-color: $primary-500 !important;
     
    h6 {
      color: white !important;
    }
  }

  .player-card{
    padding: 8px;
    border-radius: 12px;
    background-color: var(--secondary-700);
    cursor: pointer;

    

    &:hover {
      background-color: var(--secondary-600);
    }
  }

  .purse-card{
    max-width: 250px;
    padding: 2px;
  }

  .live-bid{
    max-height: 630px;
    overflow-y: auto;
  }
}


.teamImg {
  height: 75px;
  width: 75px;
  background-color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    object-fit: cover;
    height: 55px;
    width: 55px;
  }

  &.selected-team {
    background-color: $primary-500;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.user-icon{
  font-size: 20px; 
}

.rtm-card{
  font-size: 12px;
  font-weight: 600;
  color: $primary-500;
  background-color: $primary-100;
  padding: 4px 8px;
  border-radius: 12px;
}

.sold-card{
  color: red;
}

.rtm-btn{
  background-color: yellow;
  color: black;
  &:hover {
    opacity: 0.8;
  }
}

.list-player-img{
  background-color: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.player-set{
  background-color: $primary-500;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
}