.common-poll-p {
  background: var(--secondary-800) no-repeat center center / cover;
  min-height: 172px;
  border-radius: 16px;
  overflow: hidden;

  >* {
    z-index: 2;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: var(--secondary-700);
    opacity: 0.7;
    left: 0;
    top: 0;
  }

  .title{
    z-index: 10;
  }

  .poll-media {
    aspect-ratio: 16/9;
    border-radius: 8px;
    object-fit: cover;
  }

  .option {
    background: var(--secondary-900);
    border-radius: 8px;
    font-weight: 500;
    padding: 12px 16px;
  }

  .result {
    background: #a6c8ff;
    top: auto;
  }

  .img-list {
    margin: 0px -8px;
  }

  img{
    object-fit: cover;
  }

  .emoji-option {
    z-index: 2;
  }
  .value { top: auto; z-index: 3; }
}
.vs-poll-p {
  .items { transform: scale(1.1); }
  .result { z-index: 2;}
  h3 { z-index: 3;}
  .item { transform: skewX(-10deg);
      &::after { content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: var(--secondary-700); opacity: 0.35; }
  }
  .value,
  img {  transform: skewX(10deg) scale(1.2); object-fit: cover;   }
  img {  object-fit: cover; }
}