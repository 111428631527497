.story-content {
  background-color: var(--secondary-800);
  border-radius: 8px;
}
.stories-tab {
  list-style-type: none;
  border-bottom: 1px solid var(--secondary-700);
  overflow: auto;
  white-space: nowrap;
  button {
    border: none;
    border-bottom: 1px solid transparent;
  }
}

.slider {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  gap: 15px;
  padding: 0 0 20px 0;
  scrollbar-width: none;
  .slide {
    flex: 0 0 85%;
    scroll-snap-align: start;
  }
}
.slider-dots {
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--secondary-700);
    &.active {
      background-color: #045de9;
    }
  }
}

.manage-stories {
  background-color: var(--secondary-800);
  .st-img {
    aspect-ratio: 9 / 16;
    max-width: 300px;
    background-color: var(--secondary-700);
    border-radius: 8px;
  }
}
.s-view {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &.fc {
    .l-view {
      background: linear-gradient(0.5turn, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.85) 73%);
      justify-content: end;
      padding-bottom: 30px;
    }
  }
  &.sc {
    .l-view {
      background: linear-gradient(0.5turn, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.85) 73%);
      align-items: start;
      justify-content: end;
      .title {
        background-color: white;
        color: black;
        height: 50px;
        width: 50px;
        border-radius: 100%;
        line-height: 50px;
        text-align: center;
        flex-shrink: 0;
        overflow: hidden;
      }
      .title-dec {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        * {
          margin-bottom: 0;
        }
      }
    }
  }
  &.dc {
    img {
      height: auto;
      aspect-ratio: 16/10;
      object-fit: cover;
    }
    flex-direction: column;
    .l-view {
      position: static !important;
      padding: 15px 10px 35px 10px;
    }
  }
  &.pc {
    .l-view {
      align-items: start;
      justify-content: end;
      padding: 0;
      .title {
        background-color: white;
        color: black;
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        flex-shrink: 0;
        overflow: hidden;
      }
      .title-dec {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 40px;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        padding: 15px 10px;
        * {
          margin-bottom: 0;
        }
      }
    }
  }
  .l-view {
    padding: 25px 10px;
    p {
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}
.stories-type {
  label {
    width: 30px;
    padding: 2px;
  }
}
.link {
  border-radius: 50px;
}
