.modal-90w {
  max-width: 90% !important;
}

.data-table-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--secondary-700);
  overflow-x: auto;
  li {
    font-size: $fs-13;
    color: var(--secondary-400);
    font-weight: $fw-500;
    cursor: pointer;
    min-width: 145px;
    text-align: center;
    border-bottom: 2px solid transparent;
    padding: 3px 5px;
    &.active {
      border-bottom-color: var(--secondary-100);
      font-weight: $fw-600;
      color: var(--secondary-100);
    }
  }
}

.media-list { max-height: calc(100vh - 400px); overflow-y: auto; overflow-x: hidden;
 user-select: none;
  -webkit-user-drag: none;
  .media-item { position: relative; border: 4px solid transparent; border-radius: 10px; overflow: hidden;
    .icon  { position: absolute; opacity: 0; right: 12px; top: 12px; }
    .layer { position: absolute; height:100%;width:100% ; opacity: 0; top: 0;left: 0;  background-color: $primary-700;}
    &.selected { border-color: $primary-700; background-color: rgba($primary-700, 1);
      .icon  { opacity: 1;}
      .layer  { opacity: 0.3;}
    }
  }
}

.media-modal .btn-close { filter: brightness(0) invert(1);}
.middle-div{
  margin: 250px 0 250px 0
}

.big{
  font-size: 20px;
  margin: 8px 5px 0 0;
}

.square-img {
  aspect-ratio: 12 / 7;
  object-fit: cover;
  width: 100%;
}

.left-div{
  width: 70%;
}

.right-div{
  width:28%;
}

.sidebar-image{
  width:120px;
  height: 80px;
  border-radius:10px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.media-img-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 12px;
}

.dark-text{
  margin:0 0 10px 0;
  color: var(--secondary-400);
  font-size: 11px;
  font-weight: 600;
}

.footer-image {
  border-radius: 10px;
  width:60px;
  height:50px;
}

.modify-button-blue {
  background: transparent;
  border: none;
  color: $primary-500;
  padding: 0;
}

.modify-button-blue-underline{
  background: transparent;
  border: none;
  color: $primary-300;
  text-decoration: underline;
}

.modify-button-red{
  background: none;
  border: none;
  color: $red-300;
  padding: 0;
}

.media-upload-loader {
  min-height: calc(100vh - 800px);
}

.month-selector {
  min-width: 140px;
  .select__indicator{
    margin: -5px;
  }
}

.feature-image-div{
  max-height: calc(100vh - 400px); overflow-y: auto; overflow-x: hidden;
}